/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ServiceDelayPresenter } from "../presenters/ServiceDelayPresenter";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ServiceDelayApiRequest } from "@/data/payload/api/ServiceDelayApiRequest";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "service-delay"
})
class ServiceDelayStore extends VuexModule {
  @Action
  public getList(params: ApiRequestList) {
    const presenter = container.resolve(ServiceDelayPresenter);
    return presenter.getList(params);
  }

  @Action
  public getDetail(id: number) {
    const presenter = container.resolve(ServiceDelayPresenter);
    return presenter.getDetail(id);
  }

  @Action
  public create(payload: ServiceDelayApiRequest) {
    const presenter = container.resolve(ServiceDelayPresenter);
    return presenter.create(payload);
  }

  @Action
  public edit(payload: ServiceDelayApiRequest) {
    const presenter = container.resolve(ServiceDelayPresenter);
    return presenter.edit(payload);
  }
}

export const ServiceDelayController = getModule(ServiceDelayStore);
