import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "flex flex-row text-12px xxl:text-14px items-center w-full" }
const _hoisted_3 = { class: "font-semibold text-20px mr-8 whitespace-nowrap text-black-lp-200 capitalize" }
const _hoisted_4 = { class: "w-1/6 whitespace-nowrap" }
const _hoisted_5 = { class: "overflow-auto h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, null, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.params.search,
            class: "w-1/3",
            placeholder: "Cari nama kendala"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_CustomDropdown, {
              selectedItem: _ctx.params.status,
              options: _ctx.statusData,
              placeholder: "Semua Status",
              onSelect: _ctx.onSelectStatus
            }, null, 8, ["selectedItem", "options", "onSelect"])
          ])
        ]),
        (_ctx.isCreateAble)
          ? (_openBlock(), _createBlock(_component_LpButton, {
              key: 0,
              title: "Buat Area",
              textColor: "white",
              textSize: 10,
              class: "ml-6 ",
              customClass: "whitespace-nowrap",
              onClick: _ctx.goToCreate
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_TableV2, {
        loading: _ctx.listData.loading,
        borderTop: false,
        borderBottom: false,
        columns: _ctx.columns,
        data: _ctx.listData.data,
        isEmpty: !_ctx.listData.data.length && !_ctx.params.search && !_ctx.params.status,
        emptyHeaderMessage: "Tidak Ada Area Keterlambatan",
        emptyMessage: "Mulai buat area keterlambatan dan cek daftarnya disini",
        class: "my-2",
        onClick: _ctx.onClickRow,
        isDetailAble: _ctx.isDetailAble,
        pagination: _ctx.listData.pagination,
        "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.listData.pagination = $event)),
        onRequest: _ctx.fetchList,
        onTryAgain: _ctx.fetchList,
        paginationStyle: "v3"
      }, null, 8, ["loading", "columns", "data", "isEmpty", "onClick", "isDetailAble", "pagination", "onRequest", "onTryAgain"])
    ])
  ]))
}