
import { Vue } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ServiceDelayDetail } from "@/domain/entities/ServiceDelay";
import { ServiceDelayController } from "@/app/ui/controllers/ServiceDelayController";
import {
  checkRolePermission,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { SERVICE_DELAY } from "@/app/infrastructures/misc/RolePermission";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";

export default class ServiceDelayAdd extends Vue {
  get id(): number {
    return Number(this.$route.params?.id);
  }

  detailEntities = new ResponsePayloadV2();
  get detailData(): ServiceDelayDetail {
    return this.detailEntities.data;
  }
  async fetchDetail(): Promise<void> {
    try {
      this.detailEntities.loading = true;
      this.detailEntities = await ServiceDelayController.getDetail(this.id);
    } catch (error) {
      const errorEntities: ErrorMessageEntities = parsingErrorResponse(error);
      this.detailEntities.message = errorEntities.type;
    } finally {
      this.detailEntities.loading = false;
    }
  }

  mounted() {
    this.fetchDetail();
  }

  goBack(): void {
    this.$router.push("/network/service-delay");
  }
  goEdit(): void {
    this.$router.push(`/network/service-delay/${this.id}/edit`);
  }

  get isEditable(): boolean {
    return checkRolePermission(SERVICE_DELAY.EDIT);
  }
}
