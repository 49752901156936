
import { Vue, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import {
  DistrictsForm,
  ServiceDelayDetail,
  ServiceDelayErrorDetail,
  ServiceDelayFormResponse
} from "@/domain/entities/ServiceDelay";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { DistrictData } from "@/domain/entities/Location";
import { debounce } from "lodash";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { ServiceDelayController } from "@/app/ui/controllers/ServiceDelayController";
import { ServiceDelayApiRequest } from "@/data/payload/api/ServiceDelayApiRequest";
import {
  ErrorMessageEntities,
  ModalMessageEntities
} from "@/domain/entities/MainApp";
import { formatDateNumber } from "@/app/infrastructures/misc/Utils";

@Options({
  beforeRouteLeave(to: any, _: any, next: any) {
    if (this.answerLeavingPage || to.name === "login") {
      this.answerLeavingPage = false;
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class ServiceDelayAdd extends Vue {
  get id(): number {
    return Number(this.$route.params?.id);
  }
  get isEdit(): boolean {
    return !!this.id;
  }

  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    this.$router.push(
      this.isEdit
        ? `/network/service-delay/${this.id}`
        : "/network/service-delay"
    );
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  form = new ServiceDelayDetail();
  error = new ServiceDelayErrorDetail();

  detailEntities = new ResponsePayloadV2();
  get detailData(): ServiceDelayDetail {
    return this.detailEntities.data;
  }
  async fetchDetail(): Promise<void> {
    try {
      this.detailEntities.loading = true;
      this.detailEntities = await ServiceDelayController.getDetail(this.id);
      this.form = this.detailEntities.data;
      this.error = new ServiceDelayErrorDetail({
        errorName: !this.form.name,
        errorCityCode: !this.form.cityCode,
        errorDistrict: !this.form.districtData.length && !this.form.allDistrict,
        errorRemarks: !this.form.remarks,
        errorStartDate: !this.form.startDate,
        errorEndDate: !this.form.endDate
      });
      this.form = {
        ...this.form,
        districtData: this.form.allDistrict
          ? [
              {
                name: "Semua Kecamatan",
                code: "All",
              },
            ]
          : this.form.districtData,
      };
    } catch (error) {
      const errorEntities: ErrorMessageEntities = parsingErrorResponse(error);
      this.detailEntities.message = errorEntities.type;
    } finally {
      this.detailEntities.loading = false;
    }
  }
  mounted() {
    if (this.isEdit) {
      this.fetchDetail();
    }
  }

  get maxDate() {
    const today = new Date();
    today.setDate(today.getDate() + 7);
    return today
  }

  onChangeCity(): void {
    this.form.districtData = [];
    this.fetchDistrict("");
  }

  async fetchDistrict(search: string) {
    this.isLoadingDistrict = true;
    await LocationController.getDistrictList({
      search: search,
      status: "active",
      page: 1,
      limit: 20,
      type: "",
      cityCode: this.form.cityData.code,
      cache: true
    });
    this.isLoadingDistrict = false;
  }

  isLoadingDistrict = false;
  get listDistrictExcludeOptions () : DistrictData[] {
    return LocationController.districtData.districtData;
  }

  get listDistrict() {
    const resp = [
      {
        name: "Semua Kecamatan",
        code: "All"
      },
      ...this.listDistrictExcludeOptions
    ];
    return resp;    
  }
  filterDistrict = debounce(async (search: string) => {
    this.fetchDistrict(search);
  }, 250);
  onRemoveDistrict(item: DistrictData): void {
    if (this.isEdit) {
      const findForm = this.form.districtData.find(
        (el: DistrictData) => el.code === item.code
      );
      this.error.removeDistrictData.push(
        new DistrictData({
          ...item,
          sddId: findForm?.sddId,
          status: "REMOVE"
        })
      );
    }
  }

  get endDateAttributes() {
    return {
      minDate: this.form.startDate
        ? new Date(this.form.startDate)
        : this.$moment(new Date(new Date().setHours(0, 0, 0, 0)))
            .add("1", "days")
            .toDate()
    };
  }
  formatDate(date: string): string {
    return formatDate(date);
  }

  get isDisableForm(): boolean {
    return (
      this.error.errorName ||
      this.error.errorCityCode ||
      this.error.errorDistrict ||
      this.error.errorRemarks ||
      this.error.errorStartDate
    );
  }
  get filterDistrictError(): DistrictData[] {
    return this.error.removeDistrictData.filter(
      (item: DistrictData) => item.sddId
    );
  }
  mergeArrays(arr1: DistrictData[], arr2: DistrictData[]): DistrictData[] {
    const mergedArray = [...arr1, ...arr2];
    const map = new Map<string, DistrictData>();

    for (const item of mergedArray) {
      const existingItem = map.get(item.code);
      if (existingItem) {
        if (item.sddId) {
          existingItem.sddId = item.sddId;
        }
        if (item.status !== "REMOVE") {
          existingItem.status = item.status;
        }
      } else {
        map.set(item.code, item);
      }
    }

    return Array.from(map.values());
  }
  async onSave(): Promise<void> {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    this.error.districts = "";

    try {
      let errorDistricts: ServiceDelayFormResponse[] = [];
      if (!this.isEdit) {
        const res = await ServiceDelayController.create(
          new ServiceDelayApiRequest({
            payload: new ServiceDelayDetail({
              ...this.form,
              cityCode: this.form.cityData.code,
              districts: this.form.districtData[0]?.code?.toLowerCase() === "all" ? "" : this.form.districtData
                .map((el: DistrictData) => el.code)
                .join(),
              startDate: formatDateNumber(this.form.startDate),
              endDate: this.form.endDate
                ? formatDateNumber(this.form.endDate)
                : "",
              allDistrict: this.form.districtData[0]?.code?.toLowerCase() === "all"
            })
          })
        );
        errorDistricts = res.data;
      } else {
        const res = await ServiceDelayController.edit(
          new ServiceDelayApiRequest({
            payload: new ServiceDelayDetail({
              ...this.form,
              cityCode: this.form.cityData.code,
              districts: this.form.districtData[0]?.code?.toLowerCase() === "all" ? [] : this.mergeArrays(
                this.form.districtData,
                this.filterDistrictError
              ).map(
                (el: DistrictData) =>
                  new DistrictsForm({
                    sddId: el.sddId,
                    status: !el.status.match(/^remove$/gi) ? "ADD" : el.status,
                    district: new DistrictData({
                      ...el,
                      createdAt: undefined,
                      updateAt: undefined,
                      createdBy: undefined,
                      updatedBy: undefined
                    })
                  })
              ),
              startDate: formatDateNumber(this.form.startDate),
              endDate: this.form.endDate
                ? formatDateNumber(this.form.endDate)
                : "",
              allDistrict: this.form.districtData[0]?.code?.toLowerCase() === "all"
            })
          })
        );
        errorDistricts = res.data;
      }

      this.onErrorDistricts(errorDistricts);
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          `Area Keterlambatan Gagal ${this.isEdit ? "Diubah" : "Dibuat"}`,
          () => this.onSave()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  onErrorDistricts(errorDistricts: any[]): void {
    if (errorDistricts.length) {
      this.error.districts = errorDistricts
        .map((item: ServiceDelayFormResponse) => item.district.name)
        .join(", ");
      this.error.districtData = errorDistricts.map(
        (item: ServiceDelayFormResponse) =>
          new DistrictData({
            name: item.district.name,
            code: item.district.code
          })
      );
    } else {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: `Area Keterlambatan Berhasil ${
            this.isEdit ? "Diubah" : "Dibuat"
          }`,
          message: this.isEdit
            ? `Perubahan detail “${this.form.name}” telah tersimpan`
            : `Anda berhasil menambahkan ‘${this.form.name}” ke daftar area keterlambatan`,
          textSuccess: "OK",
          onSubmit: this.onCloseSuccess,
          image: "image-modal-success"
        })
      );
    }
  }
}
