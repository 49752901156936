/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { UpdateStatusRequestInterface } from "../contracts/CustomProcessRequest";
import { ServiceDelayDetail } from "@/domain/entities/ServiceDelay";

export class ServiceDelayApiRequest implements UpdateStatusRequestInterface {
  payload: ServiceDelayDetail = new ServiceDelayDetail();
  constructor(fields?: Partial<ServiceDelayApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}
